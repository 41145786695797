import { graphql } from "gatsby";
import React from "react";
import Layout from "../components/layout";
import { StaticImage } from "gatsby-plugin-image";
import styled from "styled-components";
import { Link, Trans, useTranslation } from "gatsby-plugin-react-i18next";
import { CaretRightIcon } from "../images/Icons";
import useIsMobile from "../hooks/useMobile";

import firstBg from "../images/about-us/1.jpg";
import secondBg from "../images/about-us/2.jpg";
import thirdBg from "../images/about-us/3.png";
import fourthBg from "../images/about-us/4.png";

const AboutUsPage = () => {
  return (
    <Layout pageTitle="About Us - Moniepoint Inc.">
      <HeroContainer>
        <div className="hero-content-wrapper">
          <h1 className="title">
            <Trans>About Us</Trans>
          </h1>
          <div className="content">
            <h6 className="description">
              <Trans>aboutus.hero_line_1</Trans>
              <br />
              <br />
              <Trans>aboutus.hero_line_2</Trans>
              <br />
              <br />
              <Trans>aboutus.hero_line_3</Trans>
              <br />
              <br />
              <Trans>aboutus.hero_line_4</Trans>
            </h6>
            <StaticImage
              alt="..."
              src="../images/about-us/hero.png"
              className="hero-image grow"
            />
          </div>
        </div>
      </HeroContainer>
      <OurJourneyContainer>
        <div className="content">
          <h2 className="section-title">
            <Trans>aboutus.our_journey</Trans>
          </h2>
          <TimeLineComponent />
        </div>
      </OurJourneyContainer>
      <JoinDreamTeamContainer>
        <div className="content">
          <h2 className="section-title">
            <Trans>aboutus.want_to_join</Trans>
          </h2>
          <Link to="/inside-moniepoint#roles" className="btn btn-primary">
            <Trans>aboutus.want_to_join_cta</Trans>
          </Link>
        </div>
      </JoinDreamTeamContainer>
      <OurInvestorsContainer>
        <div className="content">
          <h2 className="section-title">
            <Trans>aboutus.our_investors</Trans>
          </h2>
          <div className="logo-container">
            <StaticImage
              alt="..."
              src="../images/about-us/investors/nvf.png"
              className="investor-image"
            />
            <StaticImage
              alt="..."
              src="../images/about-us/investors/qed.png"
              className="investor-image"
            />
            <StaticImage
              alt="..."
              src="../images/about-us/investors/fmo.png"
              className="investor-image"
            />
            <StaticImage
              alt="..."
              src="../images/about-us/investors/bii.png"
              className="investor-image hidden-xs"
            />
            <StaticImage
              alt="..."
              src="../images/about-us/investors/novastar.png"
              className="investor-image"
            />
            <StaticImage
              alt="..."
              src="../images/about-us/investors/global-ventures.png"
              className="investor-image"
            />
            <StaticImage
              alt="..."
              src="../images/about-us/investors/endeavor.png"
              className="investor-image"
            />
            <StaticImage
              alt="..."
              src="../images/about-us/investors/bii.png"
              className="investor-image visible-xs"
            />
            <StaticImage
              alt="..."
              src="../images/about-us/investors/lightrock.png"
              className="investor-image scale"
            />
          </div>
        </div>
      </OurInvestorsContainer>
    </Layout>
  );
};

export default AboutUsPage;

const TimeLineComponent = () => {
  const { t } = useTranslation();
  const [activeItem, setActiveItem] = React.useState(null);
  const timeline = [
    {
      id: 1,
      title: t("aboutus.timeline_1_title"),
      year: "2015",
      image: firstBg,
      content: t("aboutus.timeline_1"),
    },
    {
      id: 2,
      title: t("aboutus.timeline_2_title"),
      year: "2019",
      image: secondBg,
      content: t("aboutus.timeline_2"),
    },
    {
      id: 3,
      title: t("aboutus.timeline_3_title"),
      year: "2020",
      image: thirdBg,
      content: t("aboutus.timeline_3a"),
      // more_content: t("aboutus.timeline_3b"),
    },
    {
      id: 4,
      title: t("aboutus.timeline_4_title"),
      year: "2022",
      image: fourthBg,
      content: t("aboutus.timeline_4"),
    },
  ];

  const isMobile = useIsMobile();
  const handleClick = (t) => {
    console.log(isMobile, activeItem)
    if (isMobile) {
      setActiveItem((value) => (value && value?.id === t.id ? null : t));
    }
  };
  return (
    <TimeLineContainer>
      {timeline.map((t, i) => (
        <TimeLineItem
          tabIndex={-1}
          src={t.image}
          key={t.id}
          className={`timeline-item ${activeItem?.id === t.id ? "item-active" : ""
            } ${activeItem && activeItem?.id !== t.id ? "item-collapse" : ""}`}
          onClick={() => handleClick(t)}
        >
          <div className="timeline-item-content">
            <h3 className="timeline-item-title">
              <Trans>{t.year}</Trans>
            </h3>
            <p className="timeline-item-description">{t.content}</p>
            {t.more_content ? (
              <p className="timeline-item-description">{t.more_content}</p>
            ) : (
              ""
            )}

            <h6 className="timeline-item-link">
              <Trans>{t.title}</Trans>{" "}
              <CaretRightIcon color="white" size={16} />
            </h6>
          </div>
        </TimeLineItem>
      ))}
    </TimeLineContainer>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["common", "index"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const HeroContainer = styled.section`
  background-color: white;

  .hero-content-wrapper {
    max-width: 1440px;
    padding: 60px 150px;
    margin: auto;
    position: relative;
    @media only screen and (max-width: 1024px) {
      padding: 60px;
    }

    .title {
      font-family: "Founders Grotesk";
      font-size: 64px;
      font-weight: 500;
      line-height: 76px;
      letter-spacing: 0em;
      text-align: left;
      margin-bottom: 40px;
    }
    .content {
      display: flex;
      gap: 79px;
    }
    .description {
      font-family: Inter;
      font-size: 17px;
      font-weight: 400;
      line-height: 28px;
      letter-spacing: 0em;
      text-align: left;
      width: fit-content;
    }
    .hero-image {
      border-radius: 16px;
      max-width: 548px;
      height: 430px;
      width: 100%;
    }
  }

  @media only screen and (max-width: 1024px) {
    .hero-content-wrapper {
      padding: 24px 20px;

      .title {
        font-size: 40px;
        line-height: 53px;
      }
      .content {
        flex-direction: column;
        gap: 48px;
      }
      .description {
        order: 2;
        font-size: 16px;
      }
      .hero-image {
        border-radius: 1px;
        margin: 0 -20px;
        height: auto;
        max-width: 100vw;
        width: 100vw;
      }
    }
  }
`;

const OurJourneyContainer = styled.section`
  background: rgba(11, 50, 117, 1);

  .content {
    max-width: 1440px;
    margin: auto;
    padding: 75px 150px;
    @media only screen and (max-width: 1024px) {
      padding: 75px 60px;
    }

    .section-title {
      font-family: "Founders Grotesk";
      font-size: 35px;
      font-weight: 500;
      line-height: 41px;
      letter-spacing: 0em;
      text-align: left;
      color: white;
      margin-bottom: 32px;
    }

    .section-button {
      text-align: center;
    }
  }
  @media only screen and (max-width: 768px) {
    .content {
      padding: 48px 20px;
      .section-title {
        font-size: 28px;
        line-height: 33px;
        margin-bottom: 48px;
      }
    }
  }
`;

const JoinDreamTeamContainer = styled.section`
  background: rgba(3, 87, 238, 0.03);

  .content {
    max-width: 1440px;
    margin: auto;
    padding: 75px 150px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media only screen and (max-width: 1024px) {
      padding: 75px 60px;
    }

    .section-title {
      font-family: "Founders Grotesk";
      font-size: 52px;
      font-weight: 500;
      line-height: 61px;
      margin-bottom: 32px;
    }
  }
  @media only screen and (max-width: 768px) {
    .content {
      padding: 61px 20px;
      .section-title {
        font-size: 36px;
        line-height: 42px;
      }
    }
  }
`;

const TimeLineContainer = styled.div`
  display: flex;
  gap: 16px;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }

  :hover *:not(:hover) {
   
    @media only screen and (min-width: 1024px) {
      .timeline-item-content {
      .timeline-item-title {
        font-size: 36px;
        line-height: 80px;
      }
      .timeline-item-link {
        display: none;
      }
    }
    }

    @media only screen and (max-width: 1024px) {
      .timeline-item-content {
        padding: 12px;
      }
    }
  }
`;

const TimeLineItem = styled.div`
  width: 100%;
  max-height: 513px;
  min-width: 0;
  border-radius: 8px;
  position: relative;
  cursor: pointer;
  background: linear-gradient(0deg, rgba(9, 34, 86, 0.3), rgba(9, 34, 86, 0.3)),
    url(${(props) => props.src});
  background-blend-mode: multiply, normal;
  background-size: cover;
  background-position: center;
  transition: all 0.5s ease-in-out;
  display: flex;
  overflow: hidden;
  

  .timeline-item-content {
    position: relative;
    z-index: 2;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 0;
    transition: padding 0.1s ease;

    .timeline-item-title {
      font-family: "Founders Grotesk";
      font-size: 52px;
      font-weight: 500;
      line-height: 80px;
      letter-spacing: 0em;
      text-align: center;
      color: white;
      margin: 200px auto 0;
      letter-spacing: 0.05em;
      transition-delay: 0.4s;
      transition: margin-left 0.6s ease-in-out, margin-top 0.6s ease-in-out,
        font-size 0.6s ease-in-out;
    }

    .timeline-item-description {
      font-size: 14px;
      font-weight: 500;
      line-height: 28px;
      letter-spacing: 0em;
      text-align: left;
      color: white;
      visibility: hidden;
      translate: 0 -20px;
      transition-delay: 0s;
      transition: visibility 0.1s ease-in-out, translate .7s ease, height 0.1s ease-in-out;
    }

    .timeline-item-link {
      position: absolute;
      font-family: "Founders Grotesk";
      font-size: 18px;
      font-weight: 500;
      line-height: 21px;
      letter-spacing: 0em;
      text-align: center;
      color: white;
      bottom: 16px;
      align-self: center;
      margin: auto;
    }
  }

  @media screen and (max-width: 1024px) {
    height: 169.25px;
    max-width: none;
    width: 100%;

    .timeline-item-content {
      padding: 12px;
      .timeline-item-title {
        font-size: 40px;
        line-height: 47px;
        text-align: center;
        margin: auto 0;
      }
      .timeline-item-description {
        height: 0%;
      }

      .timeline-item-link {
        font-size: 16px;
        line-height: 19px;
        bottom: 50px;

        svg {
          transform: rotate(90deg);
        }
        visibility: visible;
        transition: all 0.2s ease;


      }
    }

    &.item-active {
      min-width: auto;
      max-width: 100%;
      height: 100%;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
        url(${(props) => props.src});
        transition: all 0.2s ease;

      .timeline-item-content {
        padding: 20px;
        justify-content: flex-start;
        text-align: left;
        align-items: flex-start;

        .timeline-item-title {
          font-size: 40px;
          font-weight: 500;
          line-height: 47px;
          letter-spacing: 0em;
          margin: 0 0 16px;
          text-align: left;
        }
        .timeline-item-description {
          display: block;
          visibility: visible;
        transition-delay: 0.4s;
        translate: 0;
        height: 100%;
        }

        .timeline-item-link {
          visibility: hidden;
          /* transition-delay: 0.4s; */
        }
      }
    }

    &.item-collapse {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
        url(${(props) => props.src});
      background-blend-mode: multiply, normal;
      background-size: cover;
      background-position: center;
      transition: visibility 0.1s ease-in-out, translate 1s ease;

      .timeline-item-content {
        .timeline-item-link {
          visibility: hidden;
          transition-delay: 0.4s;
        }
      }
    }
  }

  @media screen and (min-width: 1024px) {
    &:hover {
      width: 375%;
      max-width: 578px;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
        url(${(props) => props.src});
      background-blend-mode: multiply, normal;
      background-size: cover;
      background-position: center;
      transition: all 0.1s ease;

      .timeline-item-content {
        padding: 40px;

        .timeline-item-title {
          font-size: 64px;
          font-weight: 500;
          line-height: 76px;
          letter-spacing: 0em;
          margin-left: 1px;
          margin-top: 0px;
          margin-bottom: 24px;
          text-align: left;
        }
        .timeline-item-description {
          visibility: visible;
          transition-delay: 0.4s;
          translate: 0;
        }

        .timeline-item-link {
          display: none;
        }
      }
    }
  }
`;

const OurInvestorsContainer = styled.section`
  background: white;

  .content {
    width: 100%;
    margin: 0 auto;
    padding: 60px 20px;

    .section-title {
      font-family: "Founders Grotesk";
      font-weight: 500;
      font-size: 28px;
      line-height: 33px;
      text-align: left;
      margin-bottom: 48px;
      letter-spacing: 0em;
      color: rgba(0, 0, 0, 1);
    }

    .logo-container {
      display: grid;
      gap: 1rem;
      grid-template-columns: repeat(3, 1fr);

      .investor-image {
        width: 100px;

        img {
          object-fit: scale-down !important;
        }

        &.scale {
          img {
            height: 80%;
          }
        }
      }
    }

    @media only screen and (min-width: 500px) {
      .logo-container {
        .investor-image {
          width: 130px;
        }
      }
    }

    @media only screen and (min-width: 640px) {
      .logo-container {
        .investor-image {
          width: 150px;
        }
      }
    }

    @media only screen and (min-width: 768px) {
      padding: 60px 48px;

      .logo-container {
        .investor-image {
          width: 180px;
        }
      }
    }

    @media only screen and (min-width: 900px) {
      padding: 60px 64px;

      .logo-container {
        .investor-image {
          width: 200px;
        }
      }
    }

    @media only screen and (min-width: 1024px) {
      padding: 75px 64px;

      .section-title {
        font-size: 35px;
        line-height: 41px;
        margin-bottom: 53px;
      }

      .logo-container {
        grid-template-columns: repeat(4, 1fr);

        .investor-image {
          width: 200px;
        }
      }
    }

    @media only screen and (min-width: 1280px) {
      padding: 75px 80px;

      .logo-container {
        .investor-image {
          width: 220px;
        }
      }
    }
  }
`;
